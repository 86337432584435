import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <>
      <div className="App">
        <header className="App-header">
          <p>Equilibrity</p>
          <p>funded debit cards for homeless folks and those in need.</p>
        </header>
      </div>
      <div className="info">
        <p>
          Site and business currently under construction! This will be a
          non-profit that uses Stripe Issuing to provide expense controlled
          cards to homeless folks and those in need to work on closing the
          wealth gap and encouraging trusted donations.
        </p>
      </div>
    </>
  );
}

export default App;
